<template>
  <div class="align-items-center">
    <span
      class="badge"
      :class="{
        'successful text-white': modelValue,
        'warning text-dark': !modelValue,
      }"
    >
      <BaseIcon name="lock" v-if="modelValue" />
      <BaseIcon name="unlock" v-else />
    </span>
  </div>
</template>
<script>
import BaseIcon from "@/components/icons/BaseIcon";

export default {
  name: "Lock",
  components: { BaseIcon },
  props: {
    id: Number,
    row: Object,
    modelValue: Boolean,
    extraRow: String,
  },
  data() {
    return {
      value: this.modelValue,
      lock: this.extraRow,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("update", this.value, false);
    },
    extraRow() {
      this.lock = this.extraRow;
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "update", "update:extraRow"],
};
</script>
